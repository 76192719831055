import { AlertController } from '@ionic/angular';
import { UtilService } from './util.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  id: string = "";
  mail: string = "";
  password: string = "";
  connect_token: string = "";
  name: string = "";
  gender: string = "";
  premium: boolean = false;
  progress: number = 0;

  public connected: boolean = false;

  constructor(private router: Router, private location: Location, private util: UtilService, private alert: AlertController) { }

  initUser(user: any) {
    console.log("=INIT=");
    console.log(user);
    localStorage.setItem("MCP_APP_ID", user.id);
    localStorage.setItem("MCP_APP_CONNECT_TOKEN", user.connect_token);
    // Init api credentials
    this.id = user.id;
    this.connected = true;
    this.mail = user.mail;
    this.name = user.first_name;
    this.gender = user.gender;
    this.connect_token = user.connect_token;
    this.premium = user.premium == 1 ? true : false;
    this.progress = user.progress;
    console.log(this.premium);
  }

  back(){
    this.location.back();
  }

  async disconnect(redirection_route = "/identification"){
    let alert = this.alert.create({
      message: "Êtes-vous sûr de vouloir vous déconnecter ?",
      buttons: [
        {
          text: "Annuler",
          role: "cancel"
        },
        {
          text: "Confirmer",
          role: "confirm",
          handler: () => {
            localStorage.removeItem("MCP_APP_ID");
            localStorage.removeItem("MCP_APP_CONNECT_TOKEN");
            this.connected = false;
            this.mail = "";
            this.password = "";
            this.connect_token = "";
            this.router.navigateByUrl(redirection_route);
            this.util.presentToast("Déconnexion réussie, à bientôt 😊");
          }
        }
      ]
    })
    ;(await alert).present();
  }

  
}
