import { DayServiceService } from './../../services/day-service.service';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from './../../services/api.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-journal-de-bord',
  templateUrl: './journal-de-bord.page.html',
  styleUrls: ['./journal-de-bord.page.scss'],
})
export class JournalDeBordPage implements OnInit {

  // Custom text
  public text: string = "";

  // Specific content
  public specific: any = {};

  // Croyances positives
  public checked_1: boolean = false;
  public checked_2: boolean = false;
  public checked_3: boolean = false;

  // Superhéro
  public hero: boolean = false;

  // Modal mode
  @Input() modal_mode: boolean = false;

  // Day
  @Input() day: any = '';
  constructor(public api: ApiService, 
              private navParams: NavParams,
              private modal: ModalController,
              public dayService: DayServiceService) {
  }

  close(){
    this.modal.dismiss();
  }

  ngOnInit(){
    this.day = this.day.replace("j", "");
    console.log("JOUR : "+this.day);
    this.get_text();
  }

  get_text(){
    this.api.call("Api/get_logbook_data/"+this.day, {}).then((data: any) => {
      this.text = data.text;
      this.hero = data.hero;
      this.checked_1 = data.checked_1;
      this.checked_2 = data.checked_2;
      this.checked_3 = data.checked_3;
      // Get specific data only if set
      if(data.specific != null){
        this.specific = data.specific;
      }else{
        this.specific = this.dayService.get_specific_day(this.day);
      }
      console.log(this.specific);
    })
  }

  save_text(){
    this.api.call("Api/logbook_text", {
      day: this.day,
      content: this.text
    }).then((text: string) => {})
  }

  check_croyance(position){
    this.api.call("Api/logbook_exercises", {
      label: 'croyances_positives',
      day: this.day,
      position: position
    }).then(() => {})
  }

  check_hero(){
    this.api.call("Api/logbook_exercises", {
      label: 'superhero',
      day: this.day,
      position: null
    }).then(() => {})
  }

  resize(e) {
    setTimeout(() => {
      e.target.style.setProperty("height", "auto", "important");
      e.target.style.setProperty("height", (e.target.scrollHeight)+'px', "important");
    }, 10);
  }

  save_specific(){
    this.api.call("Api/logbook_specific", {
      day: this.day,
      content: this.specific
    }).then((specific: string) => {})
  }

}
