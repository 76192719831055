import { ApiService } from './../services/api.service';
import { AppService } from './../services/app.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
 providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public app: AppService, public api: ApiService, public router: Router){}
 canActivate(
   next: ActivatedRouteSnapshot,
   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return new Promise((resolve, reject) => {
      this.api.auto_connect().then(()=>{resolve(true)}).catch(()=>{resolve(false)});
    });
 }
}