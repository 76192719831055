import { DictionnaryService } from './../../services/dictionnary.service';
import { FlashService } from './../../services/flash.service';

import { Component } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'flash',
    templateUrl: 'flash.component.html',
    animations: [
        trigger('messageState', [
            transition('void => *', [
                style({transform: 'translateY(-100%)'}),
                animate('200ms ease-out')
            ]),
            transition('* => void', [
                animate('200ms ease-in', style({opacity: '0'}))   
            ])
        ])
    ]
})
export class FlashComponent {

    active: boolean = false;
    message: string = '';

    content: string = '';

    constructor(private flashProvider: FlashService, private dictionnary: DictionnaryService) {
        this.flashProvider.show = this.show.bind(this);
        this.flashProvider.hide = this.hide.bind(this);
    }

    show(message, duration){

        this.message = message;
        this.active = true;

        /*
        setTimeout(() => {
            this.active = false;
        }, duration);
        */

        let def = this.dictionnary.get_definition(message);
        if(!def){
            this.content = "Aucune définition disponible pour ce mot";
        }else{
            this.content = def.content;
            this.message = def.word;
        }


    }

    hide(){
        this.active = false;
    }

}