import { AppService } from './app.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public API_URL;
  public BASE_URL;
  public PRODUCT_ID;
  public STRIPE_KEY;
  data: any = {};

  public loading: boolean = false;

  constructor(public http: HttpClient, private app: AppService, private router: Router) {
    this.BASE_URL = "https://pluto.ovh/";
    this.API_URL = "moncoach/api/index.php/";
    this.PRODUCT_ID = "premium";
    this.STRIPE_KEY = "pk_test_51HDsgACIToJNRpgrGjgFHYPn3Jn17SqTq0yh5ShMKGSXJNTmMMgjKD5ZcyBCf2q9X8T0MFaRJBN0mGRtOcJjPZda00QQ3zVqU9";
  }

  call(action, params) {

    this.loading = true;

    let token = "Basic "+btoa(this.app.mail+":"+this.app.connect_token);

    let headers = { headers: new HttpHeaders({ 'AUTHORIZE': token }) }

    return new Promise((resolve, reject) => {

      var link = this.BASE_URL + this.API_URL + action
      var data = JSON.stringify(params);

      this.http.post(link, data, headers)
        /*.map(res => res.json())*/
        .subscribe(data => {
          this.data = data;
          this.loading = false;
          resolve(this.data);
          //console.log(this.data);
        }, error => {
          this.loading = false;
          console.warn("API_ERROR");
          console.warn(error);
          reject(error);
        });
    });
  }

  auto_connect(redirect = true){

    let url_landing = "/identification";

    return new Promise((resolve, reject) => {
    if (this.app.connected) {
      console.log("USER_CONNECTED_LOCALLY");
      resolve();
    } else {
      if(localStorage.getItem("MCP_APP_ID")){
        this.call("auth/autoconnect", {mcp_app_id: localStorage.getItem("MCP_APP_ID"), mcp_app_connect_token: localStorage.getItem("MCP_APP_CONNECT_TOKEN")})
        .then((data: any) => {
          if(data.error){
            console.log("REJECT_DISTANT");
            if(redirect)
              this.router.navigateByUrl(url_landing);
            reject();
          }else if(data){
            this.app.initUser(data);
            console.log("USER_CONNECTED_DISTANT");
            resolve();
          }else{
            console.log("CLEAR_API_SERVICE");
            localStorage.clear();
            console.log("REJECT_1");
            if(redirect)
              this.router.navigateByUrl(url_landing);
            reject();
          }
        }).catch(error => {
          if(redirect)
            this.router.navigateByUrl(url_landing);
          console.log("REJECT_2");
          reject();
        })
      }else{
        reject();
        if(redirect)
          this.router.navigateByUrl(url_landing);
      }
    }
    });
  }

}
