import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DictionnaryService {

  public lexic: any = [
    {word: 'confiance en soi', content: 'C’est à quel point je me sens capable de faire quelque chose ou d’affronter une situation particulière'},
    {word: 'confiance en toi', content: 'C’est à quel point je me sens capable de faire quelque chose ou d’affronter une situation particulière'},
    {word: 'Estime de soi', content: 'C’est l’auto-évaluation, l’image, le jugement que l’on a de soi et que l’on porte sur soi'},
    {word: 'Affirmation de soi', content: 'Capacité d’exprimer tout ce qu’il y a en nous et tout ce qui nous concerne.'},
    {word: 'Acceptation/ amour de soi', content: 'C’est s’aimer d’un amour inconditionnel.'},
    {word: 'Acceptation de soi', content: 'C’est s’aimer d’un amour inconditionnel.'},
    {word: 'Objectifs (étapes)', content: '1 – Collecte d’informations<br/>2 – Analyse<br/>3 – Actions<br/>4 – Bilan d’évolution<br/>5 – Réajustement<br/>6 – Résultats'},
    {word: 'Où qui quoi', content: 'C’est l’analyse des lieux, des personnes et des sujets qui nous mettent mal à l’aise ou au contraire qui nous mettent en confiance.'},
    {word: 'Hypervigilance', content: 'État avancé de sensibilité accompagné de comportements exagérés, dont le but est de détecter les menaces.'},
    {word: 'Auto-dénigrement', content: 'Fait de se dénigrer soi-même, de dire du mal de soi-même.'},
    {word: 'People pleasures', content: 'Personnes toujours d’accord avec tout le monde dans le but d’éviter les conflits.'},
    {word: 'Attitudes controversées', content: 'Attitudes inverses des comportements dit « normaux» qu’une personne doit avoir dans une situation donnée.'},
    {word: 'Fontaine de Jouvence', content: 'Dans la mythologie, source sensée restaurer la jeunesse de quiconque boit ou se baigne dans ses eaux.'},
    {word: 'Croyances fondamentales', content: 'Croyances intégrées depuis l’enfance'},
    {word: 'Règles inconscientes', content: 'Règles internes que l’on respecte depuis l’enfance.'},
    {word: 'Valeurs', content: 'Ce en quoi une personne est digne d’estime.'},
    {word: 'Références', content: 'Exemples tirés de notre expérience personnelle de vie.'},
    {word: 'États affectifs', content: 'Nos émotions et nos ressentis.'},
    {word: 'Croyances limitantes', content: 'Croyances négatives qui nous bloquent dans notre évolution de vie pour être heureux et épanouis.'},
    {word: 'Croyance positive', content: 'C’est l’inverse d’une croyance négative. C’est une croyance qui nous permet d’avancer dans la vie et d’être heureux.'},
    {word: 'Croyances positives', content: 'C’est l’inverse d’une croyance négative. C’est une croyance qui nous permet d’avancer dans la vie et d’être heureux.'},
    {word: 'Circuits neuronaux', content: 'Circuits dans notre cerveau par lesquels passent nos pensées internes.'},
    {word: 'Mécanismes de défense', content: 'Moyens mis en place par le cerveau pour palier à quelque chose de difficile à gérer.'},
    {word: 'Rapport affectif ', content: 'Lien que l’on a avec les gens en terme d’affection.'},
    {word: 'Égoïsme', content: 'Attachement excessif à soi-même qui fait que l’on recherche exclusivement son plaisir et son intérêt personnels.'},
    {word: 'Bienveillance', content: 'Disposition favorable à l’égard de quelqu’un'},
    {word: 'Ho’oponopono', content: 'Rituel hawaïen de pardon, d’acceptation, de réconciliation et d’amour.'},
    {word: 'Principes', content: 'Règles morales auxquelles une personne, un groupe est attaché.'},
    {word: 'Respect', content: 'Sentiment qui porte à accorder à quelqu’un de la considération en raison de la valeur qu’on lui reconnaît.'},
    {word: 'Se respecter', content: 'Accorder à soi-même de la considération en raison de la valeur que l’on se reconnaît.'},
    {word: 'S.M.A.R.T', content: 'Spécifique<br/>Mesurable<br/>Atteignable<br/>Réaliste<br/>Temporellement défini'},
    {word: 'Altruisme', content: 'Disposition à s’intéresser et à se dévouer à autrui. (Contraire égoïsme)'},
    {word: 'Bonnes résolutions', content: 'Changements positifs que l’on veut réaliser. (Tradition du nouvel an en occident)'},
    {word: 'Fastidieux', content: 'Qui rebute en provoquant l’ennui, la lassitude.'},
    {word: 'Zone de confort', content: 'État psychologique dans lequel on se sent à l’aise.'},
    {word: 'Complexe', content: 'Sentiment d’être inférieur à la normale ou à un idéal désiré, soit dans un secteur déterminé, soit dans tous les domaines'},
    {word: 'Personne toxique', content: 'Une personne qui apporte du négatif aux autres sous différentes formes.'},
    {word: 'Récurrent', content: 'Qui revient souvent'},
    {word: 'Respiration abdominale', content: 'Respiration avec le bas du ventre, au niveau des abdominaux.'},
    {word: 'Respiration thoracique', content: 'Respiration avec le haut du torse, au niveau du thorax.'},
    {word: 'Cohérence cardiaque', content: 'Succession d’inspiration nasale et d’expiration buccale avec des temps de pause.'},
    {word: 'Respiration alternée', content: 'Respiration par une narine puis par l’autre.'},
    {word: 'Respiration avec projection', content: 'Associer la visualisation avec la respiration.'},
    {word: 'Respiration tactique', content: 'Visualisation de chiffres pendant la respiration.'},
    {word: 'Rupture', content: 'Cesser une relation avec quelqu’un'},
    {word: 'Étapes du deuil', content: 'Déni – Colère – Marchandage – Dépression – acceptation'},
    {word: 'Rancune', content: 'Souvenir tenace que l’on garde d’une offense, d’un préjudice, avec de l’hostilité et un désir de vengeance.'},
    {word: 'Frustration', content: 'Privation d’un bien, d’un avantage que lequel on croit pouvoir compter.'},
    {word: 'Communication', content: 'Établir une relation avec quelqu’un ou quelque chose.'},
    {word: 'Comparaison nocive', content: 'Fait de se comparer à quelqu’un à son propre désavantage.'},
    {word: 'Tolérance', content: 'Attitude qui consiste à admettre chez autrui une manière de penser ou d’agir différente de celle qu’on adopte soi-même ; fait de respecter la liberté d’autrui en matière d’opinions.'},
    {word: 'Fatigue décisionnelle', content: 'Fatigue due aux nombreuses prises de décisions que l’on prend à chaque instant'},
    {word: 'Critique constructive', content: 'Critique qui permet de s’améliorer.'},
    {word: 'Harcèlement moral', content: 'Conduite abusive par des gestes, paroles, comportements, attitudes répétées ou systématiques visant à dégrader les conditions de vie et/ou conditions de travail d’une personne.'},
    {word: 'Journal de positivité', content: 'Journal quotidien que l’on tient et dans lequel on met tout ce qu’il y a de positif dans notre vie et dans notre journée.'},
    {word: 'Étiquettes', content: 'Fait de catégoriser une personne par ses goûts ou ses préférences ou encore un trait de caractère.'},
    {word: 'Gratitude', content: 'Sentiment de reconnaissance, dire merci.'},
    {word: 'Écoute active', content: 'Écoute bienveillante basée sur la reformulation. Intérêt réel pour l’interlocuteur.'},
    {word: 'Solution Win Win', content: 'Solution gagnant – gagnant.'},
    {word: '3 étapes du changement', content: '1. Réflexion et préparation<br/>2. Passage à l\’action<br/>3. Régularité et suivi du plan d’action'},
    {word: 'Lâcher prise', content: 'Laisser les choses sur lesquelles on n’a aucun contrôle se faire naturellement.'},
    {word: 'Quatre étapes de l’apprentissage', content: '1. Incompétence inconsciente<br/>2. Incompétence consciente<br/>3. Compétence consciente<br/>4. Compétence inconsciente'},
    {word: 'Anticiper', content: 'Prévoir à l’avance'}
  ];

  constructor() {}

  get_definition(arg){
    let search = arg.replace(/^La /, "").replace(".", "").replace(/^les /, "").replace(/^la /, "").replace(/^le /, "").replace(/d’/g, "").replace(/d'/g, "").replace(/^l'/g, "").replace(/^l’/g, "").replace(/« /g, "").replace(/«/g, "").replace(" »", "").replace("»", "").replace(/-/g, " ");
    search = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    console.log(search);
    // Cas particuliers
    if(search == "acceptation/ l’amour de soi"){
      search = "Acceptation/ amour de soi";
    }
    if(search == "les people pleasures"){
      search = "people pleasures";
    }
    if(search == "sm.a.r.t."){
      search = "S.M.A.R.T";
    }
    console.log(this.lexic);
    let word = this.lexic.filter(element => 
      element.word.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/-/g, " ").toLowerCase().includes(search.toLowerCase())
    );
    if(word.length > 0){
      return word[0];
    }else{
      return false;
    }
  }

}
