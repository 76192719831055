import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MyDatasService {

  public myGender: string = "";
  public myName: string = "";
  public myIdentifier: string = "";

  constructor() {
    if(localStorage.getItem("mcp_gender")){
      this.myGender = localStorage.getItem("mcp_gender");
    }
    if(localStorage.getItem("mcp_name")){
      this.myName = localStorage.getItem("mcp_name");
    }
    if(localStorage.getItem("mcp_identifier")){
      this.myIdentifier = localStorage.getItem("mcp_identifier");
    }else{
      this.myIdentifier = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      localStorage.setItem("mcp_identifier", this.myIdentifier);
    }
  }

  set_property(property, value){
    localStorage.setItem(property, value);
  }
  
}
