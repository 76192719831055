import { AppService } from './app.service';
import { TranslateService } from '@ngx-translate/core';
import { MyDatasService } from './my-datas.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DayServiceService {

  public debug: boolean = true;
  public days = [];
  public current_day;
  public unlock_date;

  chip_names: any = {
    'j2': 'Test',
    'j3': 'Coaching',
    'j4': 'Coaching / Exercice',
    'j5': 'Coaching / Exercice',
    'j6': 'Coaching / Exercice',
    'j7': 'Exercice',
    'j8': 'Exercice',
    'j9': 'Coaching',
    'j10': 'Coaching / Exercice',
    'j11': 'Coaching / Exercice',
    'j12': 'Exercice',
    'j13': 'Coaching / Exercice',
    'j14': 'Coaching / Mission',
    'j15': 'Test',
    'j16': 'Coaching / Exercice',
    'j17': 'Coaching',
    'j18': 'Coaching / Exercice',
    'j19': 'Exercice',
    'j20': 'Coaching',
    'j21': 'Exercice',
    'j22': 'Exercice',
    'j23': 'Coaching',
    'j24': 'Coaching / Mission',
    'j25': 'Coaching / Exercice',
    'j26': 'Coaching / Exercice',
    'j27': 'Coaching',
    'j28': 'Exercice',
    'j29': 'Coaching',
    'j30': 'Bilan',
    'j31': 'Exercice',
    'j32': 'Coaching',
    'j33': 'Coaching / Mission',
    'j34': 'Coaching',
    'j35': 'Coaching',
    'j36': 'Coaching',
    'j37': 'Coaching',
    'j38': 'Coaching',
    'j39': 'Exercice',
    'j40': 'Coaching',
    'j41': 'Coaching',
    'j42': 'Coaching',
    'j43': 'Coaching / Exercice',
    'j44': 'Coaching',
    'j45': 'Mission',
    'j46': 'Test',
    'j47': 'Coaching / Exercice',
    'j48': 'Coaching',
    'j49': 'Mission',
    'j50': 'Coaching',
    'j51': 'Coaching / Mission',
    'j52': 'Coaching / Exercice',
    'j53': 'Exercice / Mission',
    'j54': 'Mission',
    'j55': 'Coaching',
    'j56': 'Coaching',
    'j57': 'Test',
    'j58': 'Coaching',
    'j59': 'Mission',
    'j60': 'Bilan / Statistiques',
    'j61': 'Coaching / Exercice',
    'j62': 'Coaching',
    'j63': 'Coaching',
    'j64': 'Coaching',
    'j65': 'Coaching / Exercice',
    'j66': 'Coaching / Exercice',
    'j67': 'Exercice',
    'j68': 'Mission',
    'j69': 'Coaching',
    'j70': 'Mission',
    'j71': 'Coaching',
    'j72': 'Coaching',
    'j73': 'Coaching / Exercice',
    'j74': 'Test',
    'j75': 'Coaching',
    'j76': 'Coaching / Mission',
    'j77': 'Coaching',
    'j78': 'Coaching',
    'j79': 'Coaching',
    'j80': 'Coaching / Exercice',
    'j81': 'Mission',
    'j82': 'Coaching',
    'j83': 'Coaching / Exercice',
    'j84': 'Coaching',
    'j85': 'Mission',
    'j86': 'Coaching',
    'j87': 'Coaching / Exercice',
    'j88': 'Coaching',
    'j89': 'Bilan / Statistiques',
    'j90': 'Coaching'
  };

  custom_titles: any = {
    'j7': 'Situations et moments',
    'j8': 'Conséquences',
    'j9': 'Jeunesse éternelle',
    'j19': 'Exercice sur les erreurs',
    'j20': 'Se pardonner',
    'j25': 'Tes objectifs',
    'j28': 'Exercice sur les domaines',
    'j29': 'Tes petites victoires',
    'j32': 'Le regard des autres',
    'j33': 'Ta zone de confort',
    'j42': 'Ton mental',
    'j44': 'Rencontres positives',
    'j47': 'Vaincre sa timidité',
    'j49': 'L\'environnement',
    'j53': 'Les défauts physiques',
    'j54': 'Mission du téléphone',
    'j57': 'Test sur les risques',
    'j59': 'Nouveaux contacts',
    'j60': 'Bilan',
    'j61': 'Faire des pauses',
    'j62': 'Ton activité professionnelle',
    'j66': 'En mode solution' ,
    'j67': 'Tes valeurs',
    'j70': 'Journal de positivité',
    'j72': 'Attitudes et parole',
    'j76': 'Gratitude',
    'j79': 'Gagnant – Gagnant',
    'j80': 'Les changements',
    'j85': 'Mission zone de confort'
  }

  specific_days: any = [
    {
      specific_index: 4,
      type: 'inputs',
      inputs: [
        {
          label: 'Vos causes personnelles',
          value: ''
        }
      ]
    },
    {
      specific_index: 5,
      type: 'inputs',
      inputs: [
        {
          label: 'Toutes les parties de TOI',
          value: ''
        }
      ]
    },
    {
      specific_index: 6,
      type: 'inputs',
      inputs: [
        {
          label: 'Ce que tu ne veux pas être',
          value: ''
        },
        {
          label: 'Idéal de toi-même',
          value: ''
        }
      ]
    },
    {
      specific_index: 7,
      type: 'inputs',
      inputs: [
        {
          label: 'Où :',
          value: ''
        },
        {
          label: 'Qui :',
          value: ''
        },
        {
          label: 'Quoi :',
          value: ''
        }
      ]
    },
    {
      specific_index: 8,
      type: 'inputs',
      inputs: [
        {
          label: 'Tes symptômes',
          value: ''
        }
      ]
    },
    {
      specific_index: 10,
      type: 'inputs',
      inputs: [
        {
          label: 'Ta dernière réelle décision',
          value: ''
        }
      ]
    },
    {
      specific_index: 12,
      type: 'inputs',
      inputs: [
        {
          label: 'Tes croyances limitantes',
          value: ''
        }
      ]
    },
    {
      specific_index: 13,
      type: 'inputs',
      inputs: [
        {
          label: 'Tes croyances positives',
          value: ''
        }
      ]
    },
    {
      specific_index: 16,
      type: 'inputs',
      inputs: [
        {
          label: 'Tes ressentis et conséquences par rapport au « non »',
          value: ''
        }
      ]
    },
    {
      specific_index: 18,
      type: 'table',
      label: '',
      rows: [
        {
          cols: [
            {
              type: "title",
              value: "Situation qui te dérange"
            },
            {
              type: "title",
              value: "Ce que ton meilleur ami te dirait"
            }
          ]
        },
        {
          cols: [
            {
              type: "input",
              value: ""
            },
            {
              type: "input",
              value: ""
            }
          ]
        }
      ],
    },
    {
      specific_index: 19,
      type: 'table',
      label: '',
      rows: [
        {
          cols: [
            {
              type: "title",
              value: "Tes erreurs passées"
            },
            {
              type: "title",
              value: "Ce que tu as appris"
            }
          ]
        },
        {
          cols: [
            {
              type: "input",
              value: ""
            },
            {
              type: "input",
              value: ""
            }
          ]
        }
      ],
    },
    {
      specific_index: 21,
      type: 'table',
      label: '',
      rows: [
        {
          cols: [
            {
              type: "title",
              value: "Tes défauts"
            },
            {
              type: "title",
              value: "Transforme en positif"
            }
          ]
        },
        {
          cols: [
            {
              type: "input",
              value: ""
            },
            {
              type: "input",
              value: ""
            }
          ]
        }
      ],
    },
    {
      specific_index: 22,
      type: 'inputs',
      inputs: [{label: 'Liste de tes qualités',value: ''}]
    },
    {
      specific_index: 25,
      type: 'smart',
      label: 'Nom de l\'objectif',
      objective_name: '',
      rows: [
        {label: 'S', value: ''},
        {label: 'M', value: ''},
        {label: 'A', value: ''},
        {label: 'R', value: ''},
        {label: 'T', value: ''},
      ]
    },
    {
      specific_index: 26,
      type: 'inputs',
      inputs: [{label: 'Idées de bonnes actions',value: ''}]
    },
    {
      specific_index: 28,
      type: 'inputs',
      inputs: [{label: 'SPECIFIC',value: ''}]
    },
    {
      specific_index: 29,
      type: 'inputs',
      inputs: [{label: 'Petites récompenses',value: ''}]
    },
    {
      specific_index: 31,
      type: 'inputs',
      inputs: [{label: 'Situations que tu appréhendes',value: ''}]
    },
    {
      specific_index: 33,
      type: 'table',
      label: '',
      rows: [
        {
          cols: [
            {type: "title",value: "Ta zone de confort"},
            {type: "title",value: "Ta prochaine étape"}
          ]
        },
        {
          cols: [
            {type: "input",value: ""},
            {type: "input",value: ""}
          ]
        }
      ],
    },
    {
      specific_index: 39,
      type: 'inputs',
      inputs: [{label: '30 bonnes raisons de t\'aimer',value: ''}]
    },
    {
      specific_index: 45,
      type: 'inputs',
      inputs: [{label: 'Bilan de la mission du regard',value: ''}]
    },
    {
      specific_index: 47,
      type: 'table',
      label: '',
      rows: [
        {
          cols: [
            {type: "title",value: "10 situations stressantes pour toi", size: 9},
            {type: "title",value: "Classement", size: 3}
          ]
        },
        {
          cols: [
            {type: "input",value: "", size: 9}, {type: "input",value: "", size: 3},
            {type: "input",value: "", size: 9}, {type: "input",value: "", size: 3},
            {type: "input",value: "", size: 9}, {type: "input",value: "", size: 3},
            {type: "input",value: "", size: 9}, {type: "input",value: "", size: 3},
            {type: "input",value: "", size: 9}, {type: "input",value: "", size: 3},
            {type: "input",value: "", size: 9}, {type: "input",value: "", size: 3},
            {type: "input",value: "", size: 9}, {type: "input",value: "", size: 3},
            {type: "input",value: "", size: 9}, {type: "input",value: "", size: 3},
            {type: "input",value: "", size: 9}, {type: "input",value: "", size: 3},
            {type: "input",value: "", size: 9}, {type: "input",value: "", size: 3}
          ]
        }
      ],
    },
    {
      specific_index: 51,
      type: 'table',
      label: '',
      rows: [
        {
          cols: [
            {type: "title",value: "Situations de communication non optimales"},
            {type: "title",value: "Comment faire différemment"}
          ]
        },
        {
          cols: [
            {type: "input",value: ""},
            {type: "input",value: ""}
          ]
        }
      ],
    },
    {
      specific_index: 52,
      type: 'inputs',
      inputs: [{label: 'Ton alimentation équilibrée',value: ''}]
    },
    {
      specific_index: 53,
      type: 'inputs',
      inputs: [{label: 'Tes défauts physiques',value: ''}]
    },
    {
      specific_index: 60,
      type: 'inputs',
      inputs: [{label: 'Bilan / évolution',value: ''}]
    },
    {
      specific_index: 61,
      type: 'inputs',
      inputs: [
        {label: 'Nombre de pauses dans la journée',value: ''},
        {label: 'Ce que tu fais lors de ces pauses',value: ''},
        {label: 'Nombre de pauses dans la semaine',value: ''},
        {label: 'Activité ou sport',value: ''},
        {label: 'Temps de vacances par an',value: ''},
        {label: 'Ce que tu fais lors de tes vacances ',value: ''}
      ]
    },
    {
      specific_index: 65,
      type: 'table',
      label: '',
      rows: [
        {
          cols: [
            {type: "title",value: "Personne inspirante"},
            {type: "title",value: "Aspects à imiter"}
          ]
        },
        {
          cols: [
            {type: "input",value: ""},
            {type: "input",value: ""}
          ]
        }
      ],
    },
    {
      specific_index: 67,
      type: 'inputs',
      inputs: [{label: 'Tes valeurs',value: ''}]
    },
    {
      specific_index: 68,
      type: 'inputs',
      inputs: [{label: 'Changements dans les 15 prochains jours',value: ''}]
    },
    {
      specific_index: 70,
      type: 'table',
      label: 'Tableau des deux nouveaux contacts',
      rows: [
        {
          cols: [
            {type: "title",value: "Nom / Prénom"},
            {type: "title",value: "Rencontre"}
          ]
        },
        {
          cols: [
            {type: "input",value: "", flag: "contact"}, {type: "input",value: ""},
            {type: "input",value: "", flag: "contact"}, {type: "input",value: ""},
          ]
        }
      ],
    },
    {
      specific_index: 80,
      type: 'table',
      label: 'Tableau des trois nouveaux contacts',
      rows: [
        {
          cols: [
            {type: "title",value: "Nom / Prénom"},
            {type: "title",value: "Rencontre"}
          ]
        },
        {
          cols: [
            {type: "input",value: "",flag: "contact"}, {type: "input",value: ""},
            {type: "input",value: "",flag: "contact"}, {type: "input",value: ""},
            {type: "input",value: "",flag: "contact"}, {type: "input",value: ""},
          ]
        }
      ],
    },
    {
      specific_index: 85,
      type: 'table',
      label: 'Exercice pour sortir de sa zone de confort',
      rows: [
        {
          cols: [
            {type: "title",value: "Nom", size: 4},
            {type: "title",value: "Réponse à la question", size: 8}
          ]
        },
        {
          cols: [
            {type: "input",value: "", size: 4}, {type: "input",value: "", size: 8},
            {type: "input",value: "", size: 4}, {type: "input",value: "", size: 8},
            {type: "input",value: "", size: 4}, {type: "input",value: "", size: 8},
            {type: "input",value: "", size: 4}, {type: "input",value: "", size: 8},
            {type: "input",value: "", size: 4}, {type: "input",value: "", size: 8},
            {type: "input",value: "", size: 4}, {type: "input",value: "", size: 8}
          ]
        }
      ],
    },
    {
      specific_index: 87,
      type: 'inputs',
      inputs: [{label: 'À qui transmettre les choses positives que tu as apprises ?',value: ''}]
    },
    {
      specific_index: 89,
      type: 'table',
      label: 'Tableau des cinq nouveaux contacts',
      rows: [
        {
          cols: [
            {type: "title",value: "Nom / Prénom"},
            {type: "title",value: "Rencontre"}
          ]
        },
        {
          cols: [
            {type: "input",value: "",flag: "contact"}, {type: "input",value: ""},
            {type: "input",value: "",flag: "contact"}, {type: "input",value: ""},
            {type: "input",value: "",flag: "contact"}, {type: "input",value: ""},
            {type: "input",value: "",flag: "contact"}, {type: "input",value: ""},
            {type: "input",value: "",flag: "contact"}, {type: "input",value: ""}
          ]
        }
      ],
    },
  ];

  constructor(public myData: MyDatasService, private translate: TranslateService, private app: AppService) {
    // Days
    if(localStorage.getItem('mcp_days')){
      this.days = JSON.parse(localStorage.getItem("mcp_days"));
    }else{
      localStorage.setItem('mcp_days', JSON.stringify([]));
    }
  }

  datesAreOnSameDay(first, second){
    return first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();
  }

  add_day(arg){
    let s = this.findWithAttr(this.days, 'day', arg);
    if(s == -1){
      let day = {
        day: arg,
        seen_date: new Date(),
        notifications: [],
      };

      // Schedule notification
      let schedule = new Date();
      schedule.setDate(schedule.getDate()+1);
      schedule.setHours(9, 0, 0);
      // Send notification
      let push_data: any = {
        "app_id": 'e201728c-6a5e-44c3-b561-e7d9f8c2fa22',
        "filters": [
          {"field": "tag", "key": "user_identifier", "relation": "=", "value": this.myData.myIdentifier}
        ],
        "send_after": schedule.toUTCString(),
        "contents": {"en": "Bonjour "+this.myData.myName+", aujourd'hui on attaque le jour "+this.current_day+1}
      };
      /*
      if(!this.debug){
        this.onesignal.postNotification(push_data).then(val => {
          day.notifications.push(val);
          this.days.push(day);
        })
      }
      */
      // USE ON DEV
      /*
      day.notifications.push("WEB_PLT");
      this.days.push(day);
      */
    }
    localStorage.setItem("mcp_days", JSON.stringify(this.days));
  }

  findWithAttr(array, attr, value) {
    for(var i = 0; i < array.length; i += 1)
        if(array[i][attr] === value)
            return i;
    return -1;
  }

  can_enter(arg){

    if(this.debug == true)
      return 1;

    if(arg == 1)
      return 1;

    let r = this.findWithAttr(this.days, "day", arg-1);
    if(r == -1){
      return -1;
    }else{
      let d = new Date(this.days[r].seen_date);
      if(this.datesAreOnSameDay(d, new Date())){
        return 0;
      }else{
        return 1;
      }
    }
  }

  increment(){
    if(localStorage.getItem('mcp_current_day')){
      let tmp = JSON.parse(localStorage.getItem('mcp_current_day'));
      this.current_day = parseInt(tmp.current_day);
      this.unlock_date = parseInt(tmp.unlock_date);
      if(this.current_day == parseInt(tmp.current_day)){
        let d = new Date(new Date().getTime() + (24 * 60 * 60 * 1000));
        d.setHours(1, 0, 0);
        localStorage.setItem('mcp_current_day', JSON.stringify({current_day: this.current_day+1, unlock_date: d}));
      }
    }else{
      let d = new Date(new Date().getTime() + (24 * 60 * 60 * 1000));
      d.setHours(1, 0, 0);
      localStorage.setItem('mcp_current_day', JSON.stringify({current_day: this.current_day+1, unlock_date: d}));
    }
  }

  get_title(day, from_home = null){
    // Custom titles for home page
    if(from_home == true && this.custom_titles.hasOwnProperty(day)){
      return this.custom_titles[day];
    }
    if(this.app.gender == 'F'){
      this.translate.use("female");
    }else{
      this.translate.use("male");
    }
    let tr = this.translate.instant(day);
      let title = undefined;
      if(typeof tr == "string"){
        title = tr.match(/<h2>(.*?)<\/h2>/);
      }
      if(title != undefined){
        return title[0].replace("<h2>","").replace("</h2>", "");
      }else{
        if(day == "j2")
          return "Testez votre confiance en vous";
        else if(day == "j11")
          return "Exercice sur les croyances";
        else if(day == "j15")
          return "Test d’affirmation de soi";
        else if(day == "j24" && this.app.gender == 'M')
          return "La posture d'un super-héros";
        else if(day == "j24" && this.app.gender == 'F')
          return "La posture d'une super-héroïne";
        else if(day == "j46")
          return "Test de timidité";
        else if(day == "j57")
          return "Test sur les risques";
        else if(day == "j60")
          return "Bilan 2eme mois";
        else if(day == "j62")
          return "Aimer son activité professionnelle";
        else if(day == "j73")
          return "Les piliers de la vie";
        else if(day == "j74")
          return "Test sur le besoin de reconnaissance";
        else if(day == "j89")
          return "Bilan final";
        else
          return "Bilan final";
      } 
  }

  get_chip_name(day){
    if(this.chip_names.hasOwnProperty(day)){
      return this.chip_names[day];
    }else{
      return "";
    }
  }

  get_specific_day(day){
    let s_day = this.specific_days.filter(el => el.specific_index == day);
    if(s_day.length > 0){
      return s_day[0];
    }else{
      return null;
    }
  }

  get_specific_text(day){
    if(day == 28){
      if(this.app.gender == "F")
        return "Domaines où tu es forte"
      else
        return "Domaines où tu es fort"
    }
  }

}
