import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlashService {

  constructor() {
    console.log('Hello FlashProvider Provider');
  }

  show(message, duration){
  }

  hide(){

  }

}
