import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'accueil',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'accueil/:href',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'identification',
    pathMatch: 'full'
  },
  {
    path: 'identification',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'etape-jour/2',
    loadChildren: () => import('./pages/details-special/details-special.module').then( m => m.DetailsSpecialPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'etape-jour/11',
    loadChildren: () => import('./pages/details-special/details-special.module').then( m => m.DetailsSpecialPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'etape-jour/15',
    loadChildren: () => import('./pages/details-special/details-special.module').then( m => m.DetailsSpecialPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'etape-jour/24',
    loadChildren: () => import('./pages/details-special/details-special.module').then( m => m.DetailsSpecialPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'etape-jour/46',
    loadChildren: () => import('./pages/details-special/details-special.module').then( m => m.DetailsSpecialPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'etape-jour/57',
    loadChildren: () => import('./pages/details-special/details-special.module').then( m => m.DetailsSpecialPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'etape-jour/60',
    loadChildren: () => import('./pages/details-special/details-special.module').then( m => m.DetailsSpecialPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'etape-jour/62',
    loadChildren: () => import('./pages/details-special/details-special.module').then( m => m.DetailsSpecialPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'etape-jour/73',
    loadChildren: () => import('./pages/details-special/details-special.module').then( m => m.DetailsSpecialPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'etape-jour/74',
    loadChildren: () => import('./pages/details-special/details-special.module').then( m => m.DetailsSpecialPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'etape-jour/89',
    loadChildren: () => import('./pages/details-special/details-special.module').then( m => m.DetailsSpecialPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'etape-jour/:id',
    loadChildren: () => import('./details/details.module').then( m => m.DetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'conditions-generales-utilisation',
    loadChildren: () => import('./pages/cgu/cgu.module').then( m => m.CguPageModule)
  },
  {
    path: 'mentions-legales',
    loadChildren: () => import('./pages/legal/legal.module').then( m => m.LegalPageModule)
  },
  {
    path: 'bienvenue-dans-le-challenge',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'details-special',
    loadChildren: () => import('./pages/details-special/details-special.module').then( m => m.DetailsSpecialPageModule)
  },
  {
    path: 'application-mobile',
    loadChildren: () => import('./pages/mobile-disclaimer/mobile-disclaimer.module').then( m => m.MobileDisclaimerPageModule)
  },
  {
    path: 'journal-de-bord',
    loadChildren: () => import('./components/journal-de-bord/journal-de-bord.module').then( m => m.JournalDeBordPageModule)
  },
  {
    path: 'offrir-le-challenge',
    loadChildren: () => import('./pages/offrir-le-challenge/offrir-le-challenge.module').then( m => m.OffrirLeChallengePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
