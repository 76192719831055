import { EventsService } from './events.service';
import { Injectable } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(public toastCtrl: ToastController, 
              public loadingCtrl: LoadingController,
              private events: EventsService) {
  }

  async presentToast(message) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 2500
    });
    toast.present();
  }

  async presentToastNoDimiss(message){
    let toast = await this.toastCtrl.create({
      message: message,
      position: "top",
      cssClass: "toastnotif",
      buttons: [
        {
          text: 'Oui !',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  async presentLoadingDefault(message) {
    const loading = await this.loadingCtrl.create({
      message: message
    });
    loading.present();
    setTimeout(() => {
      loading.dismiss();
    }, 1300);
  }

  journalDeBord(day){
    this.events.publish("jdb-open", day);
  }

}
