import { EventsService } from './services/events.service';
import { JournalDeBordPage } from './components/journal-de-bord/journal-de-bord.page';
import { Component } from '@angular/core';

import { ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private modal: ModalController,
    private events: EventsService
  ) {
    this.initializeApp();
  }

  initializeApp() {

    // If device is mobile, cannot access app
    const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
    
    if(isMobileDevice && window.location.href.indexOf("?user_id") == -1){
      this.router.navigateByUrl("application-mobile");
    }

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.events.subscribe("jdb-open", day => {
      this.journalDeBord(day);
    })

  }

  async journalDeBord(day){
    let m = await this.modal.create({
      component: JournalDeBordPage,
      componentProps: {
        day: day,
        modal_mode: true
      }
    })
    m.present();
  }

}
